// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-js": () => import("./../../../src/pages/aviso.js" /* webpackChunkName: "component---src-pages-aviso-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mision-js": () => import("./../../../src/pages/mision.js" /* webpackChunkName: "component---src-pages-mision-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-sucursales-js": () => import("./../../../src/pages/sucursales.js" /* webpackChunkName: "component---src-pages-sucursales-js" */)
}

